<template>
  <v-container>
    <v-row class="mt-2">
      <v-col cols="2" sm="2" md="2" lg="2">
        <h2>調査管理トップ</h2>
      </v-col>
      <v-col cols="2" sm="2" md="2" lg="2">
        <v-select
          label="調査"
          v-model="targetSurveyType"
          :items="surveyType"
          dense
          :readonly="attribute != 2"
          :clearable="false"
          :rules="[requiredSelect]"
          :loading="loading"
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-if="( attribute == 2 )" class="justify-center">
      <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
        <v-btn rounded class="text-body-1" width="500" height="80" color="primary" link to="/survey_send" :disabled="loading || !selectedSurveyType || targetSurveyType === 1">調査依頼編集・送信</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
        <v-btn rounded class="text-body-1" width="500" height="80" color="primary" link to="/survey_list" :disabled="loading || !selectedSurveyType">回答状況チェック</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
        <v-btn 
          rounded 
          class="text-body-1" 
          width="500" 
          height="80" 
          color="primary" 
          link :to="(targetSurveyType === SURV_CITY_DATA || targetSurveyType === SURV_FACT_FINDING) ? '/survey_check' : 
                    (targetSurveyType === SURV_ELECTRICITY) ? '/electricity_list' : 
                    (targetSurveyType === SURV_REFRIGERANT) ? '/refrigerant_list' : '/'" 
          :disabled="loading || !selectedSurveyType"
        >回答データチェック・集計</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="( attribute == 2 )" class="justify-center">
      <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
        <v-btn rounded class="text-body-1" width="500" height="80" color="primary" link to="/survey_reply" :disabled="loading || !selectedSurveyType || (targetSurveyType === 1 || targetSurveyType === 3 || targetSurveyType === 4)">個別返答</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Const from '../mixins/const';
import Debug from "../Lib/Debug";

export default {
  mixins: [Const],

  components: {
  },

  data: () => ({
    attribute: 0,

    loading: false,

    // 対象調査種別
    targetSurveyType: null,
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;

    // 対象調査種別の設定
    this.targetSurveyType = this.SURV_CITY_DATA;  // デフォルト：月次報告データ
    if(this.attribute == 2) {
      // 日冷倉ユーザ
      Debug.log("日冷倉ユーザ");
      if(this.$store.getters.getTargetSurveyType != "" &&
          this.$store.getters.getTargetSurveyType != null)
      this.targetSurveyType = this.$store.getters.getTargetSurveyType;
    } else {
      // 都道府県協会ユーザは月次報告データのみ設定可能
      Debug.log("都道府県協会ユーザ");
      this.targetSurveyType = this.SURV_CITY_DATA;
    }
  },

  mounted: async function () {
  },

  methods: {
    // changeTargetSurveyType() {
    //   this.$store.commit('setTargetSurveyType', this.targetSurveyType);
    // },
  },

  computed: {
    selectedSurveyType() {
      return !!this.targetSurveyType || false;
    },
  },

  watch: {
    targetSurveyType: function(newVal) {
      this.$store.commit('setTargetSurveyType', newVal);
    },
  },
};
</script>

<style scoped>

</style>
